th.tableCellAlignCenter,
td.tableCellAlignCenter {
  text-align: center !important;
}

td {
  cursor: pointer;
}

/* table에 y축 scroll을 줬을 때 header와 body가 분리되는 현상을 해소하는 방법 중 하나 */
/* 모든 column의 width가 같아진다는 문제가 있음 */
/* .ant-table-thead tr {
  display: flex;
}
.ant-table-thead th {
  flex: 1;
}
.ant-table-row {
  display: flex;
}
.ant-table-row td {
  flex: 1;
  overflow: auto;
}
.ant-table-row td::-webkit-scrollbar {
  display: none;
}
.columns {
  display: flex;
  align-items: center;
  width: 0;
} */
/* 끝 */
